import { ModalServicesContext } from './ModalServicesContext';
import { ModalServicesProviderProps } from './types';

export const ModalServicesProvider: React.FC<ModalServicesProviderProps> = ({
  instances,
  children,
}) => {
  return (
    <ModalServicesContext.Provider value={instances}>
      {children}
    </ModalServicesContext.Provider>
  );
};
