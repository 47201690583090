import type { GetResult } from '@fingerprintjs/fingerprintjs-pro';

export const EMPTY_GET_RESULT: GetResult = {
  visitorId: 'none',
  visitorFound: false,
  confidence: {
    score: 0,
  },
  requestId: 'none',
};
