import { useContext } from 'react';

import { ModalServicesContext } from './ModalServicesContext';
import { ModalServices } from './types';

export const useModalServices = (): ModalServices => {
  const context = useContext(ModalServicesContext);
  if (!context) {
    throw new Error(
      'useModalServices must be used within a ModalServicesProvider'
    );
  }
  return context;
};
