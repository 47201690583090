import type { Client } from '@sentry/core';
import { addBreadcrumb, captureException, setContext } from '@sentry/core';

import {
  SentryServiceBrowser,
  SentryServiceCore,
  SentryServiceImplementationPackage,
  SentryServiceImplementationPackageBrowser,
  SentryServiceImplementationPackageReact,
  SentryServiceInitOptions,
  SentryServiceReact,
} from './types';

const checkSentryServiceImplementationPackageReactCapabilities = (
  sentryImplementation: SentryServiceImplementationPackage
): sentryImplementation is SentryServiceImplementationPackageReact => {
  return (
    'ErrorBoundary' in sentryImplementation &&
    'withErrorBoundary' in sentryImplementation
  );
};

export function createSentryService<
  IP extends SentryServiceImplementationPackage
>(
  sentryImplementation: IP
): IP extends SentryServiceImplementationPackageReact
  ? SentryServiceReact
  : SentryServiceBrowser;

export function createSentryService(
  sentryImplementation:
    | SentryServiceImplementationPackageReact
    | SentryServiceImplementationPackageBrowser
): SentryServiceReact | SentryServiceBrowser {
  let client: null | Client = null;

  const init = async ({
    dsn,
    environment,
    debug = false,
    enabled,
    logger,
    integrations = [],
  }: SentryServiceInitOptions) => {
    if (typeof window === 'undefined') {
      logger.debug('sentry init called on server, skipping...');
      return;
    }

    const options: Parameters<typeof sentryImplementation.init>[0] = {
      dsn,
      environment,
      enabled,
      debug,
      sampleRate: 0.2,
      tracesSampleRate: environment === 'production' ? 0.2 : 0,
      beforeSend: (event) => {
        if (typeof window === 'undefined') {
          return null; // do not send events on server
        }
        return event;
      },
      integrations,
    };

    logger.debug(
      `initializing sentry with options: ${JSON.stringify(options, null, 2)}`
    );

    const clientInstance = sentryImplementation.init(options);

    if (!clientInstance) {
      logger.warn('no sentry client instance created, skipping client setup');
      return;
    }

    client = clientInstance;

    logger.info('sentry client created successfully');
  };

  const core: SentryServiceCore = {
    init,
    captureException: (...args) => {
      if (!client) return '';
      return captureException(...args);
    },
    captureEvent: (...args) => {
      if (!client) return '';
      return client.captureEvent(...args);
    },
    captureMessage: (...args) => {
      if (!client) return '';
      return client.captureMessage(...args);
    },
    setContext: (...args) => {
      if (!client) return;
      setContext(...args);
    },
    addBreadcrumb: (...args) => {
      if (!client) return;
      addBreadcrumb(...args);
    },
  };

  if (
    checkSentryServiceImplementationPackageReactCapabilities(
      sentryImplementation
    )
  ) {
    return {
      ...core,
      ErrorBoundary: sentryImplementation.ErrorBoundary,
      withErrorBoundary: sentryImplementation.withErrorBoundary,
    } as SentryServiceReact;
  }

  return core;
}
