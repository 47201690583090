import {
  ConfigurationParserDefinition,
  InferInitialConfiguration,
  InferParsedConfiguration,
} from './types';

export const createConfigParser =
  <D extends ConfigurationParserDefinition>(definition: D) =>
  (input: InferInitialConfiguration<D>): InferParsedConfiguration<D> => {
    const result: Partial<InferParsedConfiguration<D>> = {};

    for (const key in definition) {
      result[key] = definition[key].defaultValue;
      try {
        result[key] = definition[key](input[key]);
      } catch (err) {
        console.error(
          `could not parse value for "${key}", got "${input[key]}, falling back to default value`
        );
      }
    }

    return result as InferParsedConfiguration<D>;
  };
