import type {
  addBreadcrumb,
  captureException,
  Client,
  ClientOptions,
  setContext,
} from '@sentry/core';
import type { ErrorBoundary, withErrorBoundary } from '@sentry/react';

import type { Logger } from '../logger';

export enum SentryServiceEnvironment {
  Local = 'local',
  Development = 'development',
  Sandbox = 'sandbox',
  Production = 'production',
}

export type SentryServiceInitOptions = {
  dsn: string;
  environment: SentryServiceEnvironment;
  debug?: boolean;
  enabled: boolean;
  logger: Logger;
  integrations?: ClientOptions['integrations'];
};

export type SentryServiceImplementationPackageCore = {
  init: (
    options: Omit<ClientOptions, 'transport' | 'stackParser'>
  ) => Client | undefined;
};

export type SentryServiceImplementationPackageReact =
  SentryServiceImplementationPackageCore & {
    ErrorBoundary: typeof ErrorBoundary;
    withErrorBoundary: typeof withErrorBoundary;
  };

export type SentryServiceImplementationPackageBrowser =
  SentryServiceImplementationPackageCore;

export type SentryServiceImplementationPackage =
  | SentryServiceImplementationPackageBrowser
  | SentryServiceImplementationPackageReact;

export type SentryServiceCore = {
  init: (options: SentryServiceInitOptions) => Promise<void>;
  captureException: typeof captureException;
  captureEvent: Client['captureEvent'];
  captureMessage: Client['captureMessage'];
  setContext: typeof setContext;
  addBreadcrumb: typeof addBreadcrumb;
};

export type SentryServiceBrowser = SentryServiceCore;

export type SentryServiceReact = SentryServiceCore & {
  ErrorBoundary: typeof ErrorBoundary;
  withErrorBoundary: typeof withErrorBoundary;
};

export type SentryService = SentryServiceBrowser | SentryServiceReact;
