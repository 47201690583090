import { logger as defaultLogger } from '@checkout-ui/shared-logger';
import type { Agent } from '@fingerprintjs/fingerprintjs-pro';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

import type { Logger } from '../logger';
import type { SentryService } from '../sentry/types';
import { EMPTY_GET_RESULT } from './constants';
import type { FingerprintJsService } from './types';

export const createFingerprintJsService = (): FingerprintJsService => {
  let client: null | Agent = null;
  let logger: Logger = defaultLogger;
  let sentry: null | SentryService = null;
  let enabled = false;

  return {
    init: async (config): Promise<void> => {
      logger = config.logger;
      sentry = config.sentry;
      enabled = config.enabled;

      if (!enabled) {
        return;
      }

      try {
        client = await FingerprintJS.load({
          apiKey: config.apiKey,
          region: 'eu',
          scriptUrlPattern: [
            'https://fp.billie.io/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
            FingerprintJS.defaultScriptUrlPattern,
          ],
          endpoint: ['https://fp.billie.io', FingerprintJS.defaultEndpoint],
        });
        logger.info('fingerprintjs client initialized');
      } catch (error) {
        logger.warn('failed to load fingerprintjs', error);
        if (error instanceof Error) {
          sentry.captureMessage(error.message);
        } else {
          sentry.captureMessage(`Unknown error: ${JSON.stringify(error)}`);
        }
      }
    },
    trackDeviceFingerprint: async (token) => {
      if (!enabled) {
        logger.debug(
          'fingerprintjs is disabled, but received track call, skipping...'
        );
        return EMPTY_GET_RESULT;
      }

      if (!client || !logger || !sentry) {
        logger.warn(
          'fingerprintjs client not initialized properly, but received track call, skipping...'
        );
        return EMPTY_GET_RESULT;
      }

      try {
        const result = await client.get({ linkedId: token });
        logger.info(
          `logged device fingerprint with visitorId: ${result.visitorId}`
        );
        return result;
      } catch (error) {
        if (error instanceof Error) {
          logger.warn('failed to make fingerprintjs get request', error);
          sentry.captureMessage(error.message);
        } else {
          sentry.captureMessage(
            `unknown fingerprintjs error: ${JSON.stringify(error)}`
          );
        }
        return EMPTY_GET_RESULT;
      }
    },
  };
};
